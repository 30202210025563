import { useState } from "react";
import {
  Box,
  Text,
  Input,
  Select,
  Flex,
  Button,
  Spinner,
  Textarea,
  useColorMode,
  VisuallyHidden,
  Tooltip
} from "@chakra-ui/react";
import useSessionInfoForm from "../../../Core/Hooks/useSessionInfoForm";
import T from "../../../Core/Translations";
import LanguagesDropdown from "../../LanguagesDropdown/Web";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CreateData } from "../../../Core/Hooks/useSessionCreate";
import { useParams, useSearchParams } from "react-router-dom";
import SpeakingLanguagesDropdown from "../../SpeakingLanguagesDropdown/Web";
import { CreatableSelect } from "chakra-react-select";
import ModeSetting from "../../SessionModes/Web";
import SpeechLanguagesDropdown from "../../SpeechLanguagesDropdown/Web";
import SubscriptionsModel from "../../../Models/Subscriptions";
import { useSelector } from "react-redux";
import { RootState } from "../../../Core/Data/Store/Reducers";

import { CLR_PANEL_BG, CLR_PANEL_BG_DARK } from "../../../../Lib/Theme/Light/colors";

interface SessionFormInfoProps {
  submitButtonText: string;
  submitButtonTextRedirect?: string;
  handleSubmit: (createData: CreateData, isRedirect?: boolean) => void;
  loadingSubmit: boolean;
}

const SessionFormInfo = ({
  submitButtonText,
  submitButtonTextRedirect,
  handleSubmit,
  loadingSubmit,
}: SessionFormInfoProps) => {
  const { sessionId } = useParams();

  const {
    sessionName,
    onChangeSessionName,
    sessionAccessType,
    sessionDescription,
    onChangeSessionDescription,
    onChangeDictionary,
    onChangeCaptionLanguages,
    onChangeSpeechLanguages,
    onChangeSessionAccessType,
    onChangeSpeakingLanguage,
    onToggleSaveToProfileMode,
    onToggleTranslateMode,
    onToggleSpeechToSpeechMode,
    speechToSpeechMode,
    translateMode,
    autoClean,
    speakingLanguage,
    captionLanguages,
    speechLanguages,
    sessionType,
    dictionaries,
    dictionaryId,
    isPageLoading,
    consumePerMinute,
    moveSessionDataToQa,
  } = useSessionInfoForm(sessionId);

  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const subscription = useSelector(
    (state: RootState) => state.user.subscription as SubscriptionsModel
  );

  const isTeams = window.location.ancestorOrigins && Array.from(window.location.ancestorOrigins).some((origin) => origin.includes("teams"));
  const minutesUsed = subscription?.minutesUsed ?? 0;
  const maxMinutes = subscription?.freeTrial
    ? subscription?.trial?.maxMinutes
    : subscription?.planData?.usageMinutes ?? 0;
  const hasExceededMaxMinutes = (minutesUsed + consumePerMinute) > maxMinutes;

  const createData: CreateData = {
    sessionName,
    sessionDescription,
    sessionAccessType,
    speakingLanguage: speakingLanguage.map((lang) => lang.value),
    languages: captionLanguages.map((lang) => lang.value),
    speechLanguages: speechLanguages.map((lang) => lang.value),
    sessionType,
    dictionaryId,
    tags: selectedTags.map((tag) => tag.label),
    autoClean: autoClean
  };

  const { colorMode } = useColorMode()

  const [searchParams] = useSearchParams();
  const isAccess = searchParams.get("isAccess") === "true";

  if (isPageLoading) {
    return <Spinner />;
  }

  return (
    <Box>
      <Flex flexDir="column" gap="5" style={{ overflowY: 'auto' }}>
        <Text fontSize={14} fontWeight={400} marginBottom={"-10px"}>
          {T.get("Select which features you want in your session here:")}
        </Text>
        <Flex flexDir={'row'} justifyContent={"space-evenly"} gap="4px">
          <VisuallyHidden>{T.get('Activating "Translate" and "Speech" buttons will update the content below.')}</VisuallyHidden>
          <ModeSetting
            title={T.get("Transcribe")}
            infoText={T.get("Transcribe audio into text")}
            onClick={() => { }}
            selected={true}
          />
          <ModeSetting
            title={T.get("Translate")}
            infoText={T.get("Instantly translate transcriptions")}
            onClick={onToggleTranslateMode}
            selected={translateMode}
          />
          <ModeSetting
            title={T.get("Speech")}
            infoText={T.get("Audio to audio translation")}
            onClick={onToggleSpeechToSpeechMode}
            selected={speechToSpeechMode}
          />
          <ModeSetting
            title={T.get("Retain")}
            infoText={T.get("Keep your transcriptions and translations")}
            onClick={onToggleSaveToProfileMode}
            selected={!autoClean}
          />
        </Flex>
        <Box>
          <Text fontSize={14} fontWeight={400} pb={2}>
            {T.get("Session name")}
            <Text as="span" aria-hidden="true"> *</Text>
          </Text>
          <Input
            shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
            fontWeight="400"           
            variant={"beVariant"}
            _placeholder={{ fontWeight: "normal" }}           
            placeholder={T.get("Please enter session Name")}
            value={sessionName}
            onChange={(e) => onChangeSessionName(e.target.value)}
            isRequired={true}
          />
        </Box>
        <SpeakingLanguagesDropdown
          title={T.get("Languages to listen for")}
          onChange={onChangeSpeakingLanguage}
          initialLanguages={[{ value: "en-US", label: "English" }]}
          onLoaded={() => { }}
          selectedLanguages={speakingLanguage}
        />
        {translateMode &&
          <LanguagesDropdown
            title={T.get("Caption languages")}
            onChange={onChangeCaptionLanguages}
            initialLanguages={[{ value: "en-US", label: "English" }]}
            onLoaded={() => { }}
            selectedLanguages={captionLanguages}
          />
        }
        {speechToSpeechMode &&
          <SpeechLanguagesDropdown
            title={T.get("Speech languages")}
            onChange={onChangeSpeechLanguages}
            onLoaded={() => { }}
            selectedLanguages={speechLanguages}
          />
        }
        <Box>
          <Text fontSize={14} fontWeight={400} pb={2}>
            {T.get("Glossary")}
          </Text>
          <Select
            shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
            icon={<ChevronDownIcon />}
            aria-label="Select a glossary"
            variant="outline"           
            bg={colorMode === 'light' ? 'white' : 'black'}     
            fontWeight={dictionaryId ? "600" : "normal"}
            size="md"
            value={dictionaryId}
            onChange={(e) => onChangeDictionary(e.target.value)}
            placeholder="Select a glossary"
          >
            {dictionaries.map((dictionary) => (
              <option value={dictionary._id} key={dictionary._id}>
                {dictionary.name || "Unnamed glossary"}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text fontSize={14} fontWeight={400} pb={2}>
            {T.get("Tags")}
          </Text>
          <CreatableSelect
            useBasicStyles
            chakraStyles={{
              downChevron: () => ({
                _light: {
                  color: "white",
                },
                _dark: {
                  color: "black",
                },
              }),
              control: (provided) => ({
                ...provided,
                _light: {
                  bg: "white",
                },
                _dark: {
                  bg: "black",
                },
                shadow: "0px 4px 4px 0px rgba(74, 74, 74, 0.04)",
              }),
            }}            
            closeMenuOnSelect={false}
            isMulti
            options={selectedTags}
            onChange={(e: any) => setSelectedTags(e)}
            placeholder="Add tag"
          />
        </Box>
        <Box>
          <Text fontSize={14} fontWeight={400} pb={2}>
            {T.get("Session type")}
          </Text>
          <Select
            shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
            icon={<ChevronDownIcon />}
            aria-label="Session type"
            variant="outline"        
            bg={colorMode === 'light' ? 'white' : 'black'}      
            fontWeight={sessionAccessType ? "600" : "normal"}
            size="md"
            value={sessionAccessType}
            onChange={(e) => onChangeSessionAccessType(e.target.value)}
          >
            <option value={"public"} key={"public"}>
              {T.get("Open")}
            </option>
            <option value={"authorized"} key={"authorized"}>
              {T.get("Closed")}
            </option>
          </Select>
          {!isTeams && <Box>
            <Text fontSize={14} fontWeight={400} pb={2} pt={5}>
              {T.get("Description")}
            </Text>
            <Textarea
              shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
              aria-label="Session type"
              variant="outline"
              bg={colorMode === 'light' ? 'white' : 'black'}                            
              fontWeight={sessionAccessType ? "600" : "normal"}
              size="md"
              onChange={(e) => onChangeSessionDescription(e.target.value)}
            />
          </Box>}
        </Box>
      </Flex>
      <Box position="sticky" bottom="0" pb={5} pt={2} zIndex={1} justifyContent="center" width="100%" bg={colorMode === 'light' ? CLR_PANEL_BG : CLR_PANEL_BG_DARK}>
        <Text fontSize={14} fontWeight={500} pb={2}>
          {T.get(`This session will consume ${consumePerMinute} minutes per running 1 minute`)}
        </Text>
        {moveSessionDataToQa && (
          <Text fontSize={12} fontWeight={600}>
            * {T.get(`Content from this session can be used for quality assurance.`)}
          </Text>
        )}
        <Flex>
          <Tooltip label={hasExceededMaxMinutes ? T.get("You have exceeded the maximum minutes allowed for your plan.") : ""}>
            <Button
              variant="beSecondary"
              w="full"
              margin={"2px"}
              isDisabled={!sessionName || loadingSubmit || hasExceededMaxMinutes}
              onClick={() => handleSubmit(createData)}
              isLoading={loadingSubmit}
            >
              {submitButtonText}
            </Button>
          </Tooltip>
          {isAccess &&
            (
              <Tooltip label={hasExceededMaxMinutes ? T.get("You have exceeded the maximum minutes allowed for your plan.") : ""}>
                <Button
                  variant="beSecondary"
                  w="full"
                  margin={"2px"}
                  isDisabled={!sessionName || loadingSubmit || hasExceededMaxMinutes}
                  onClick={() => handleSubmit(createData, true)}
                  isLoading={loadingSubmit}
                >
                  {submitButtonTextRedirect}
                </Button>
              </Tooltip>
            )}
        </Flex>
      </Box>
    </Box>
  );
};

export default SessionFormInfo;